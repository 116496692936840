import "./assets/style/style.css"
import 'react-toastify/dist/ReactToastify.css';
import { useRoutes } from "react-router-dom";
import  { ThemeRoutes } from "./routes/Router";
import {Beforeroutes} from "./routes/Router";
import { useSelector } from "react-redux";
import 'bootstrap/dist/js/bootstrap.min.js';
import Echo from 'laravel-echo';
import { useEffect } from "react";
    // eslint-disable-next-line
import Pusher from 'pusher-js'
import { toast, ToastContainer } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

const App = () => {

  const { token,user_type,user_ID } = useSelector((state) => state.auth);

  const echo = new Echo({
    broadcaster: 'pusher',
    key: '123456789',
    cluster: 'mt1',
    host: 'api.makfuels.com',
    wsHost: 'api.makfuels.com',
    wssPort: 6001,
    wsPort: 6001,
    disableStats: true,
  });
  

  useEffect(() => {


    if(user_type==="kitchen"){
      echo.channel('order-created')
          .listen('Noti', (e) => {
            toast.success(e.notification.text)
          console.log(e.notification.text);
      });
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    if(user_type=="rider"){
      echo.channel('rider-notification')
    .listen('RiderAssigned', (e) => {
    // eslint-disable-next-line
            if(user_ID == e.notification.user_id ){
            toast.success(e.notification.text)
          console.log(e.notification);
        }
      });
    }
    // eslint-disable-next-line
  }, [])

  const routing = useRoutes(ThemeRoutes);
  const beforeLoginRouting = useRoutes(Beforeroutes);

  if (!token)
  { 
    return <div className="dark">{beforeLoginRouting}</div>;
  } 
  else {
    return(
     <div className="dark">
      {routing}
      <ToastContainer />
      </div>
    

     )}
  
};

export default App;
