
import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const Login = lazy(() => import("../views/Login"));

const Product = lazy(() => import("../views/ui/Product"));
const AddProduct = lazy(() => import("../views/ui/AddProduct"));
const ProductDetail = lazy(() => import("../views/ui/ProductDetail"));

const AddCategory = lazy(() => import("../views/ui/AddCategory"));
const Category = lazy(() => import("../views/ui/Category"));

// const SubCategory = lazy(() => import("../views/ui/SubCategory"));
// const AddSubCategory = lazy(() => import("../views/ui/AddSubCategory"));

const Addons = lazy(() => import("../views/ui/Addons"));
const AddAddons = lazy(() => import("../views/ui/AddAddons"));
// const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));

const MainCategory = lazy(() => import("../views/ui//MainCategory"));
const AddMainCategory = lazy(() => import("../views/ui/AddMainCategory"));

const Banner = lazy(() => import("../views/ui/Banner"));
const AddBanner = lazy(() => import("../views/ui/AddBanner"));

const DisplayMenu = lazy(() => import("../views/ui/DisplayMenu"));
const AddDisplayMenu = lazy(() => import("../views/ui/AddDisplayMenu"));

const Coupon = lazy(() => import("../views/ui/Coupon"));
const AddCoupon = lazy(() => import("../views/ui/AddCoupon"));
const UserCoupon = lazy(() => import("../views/ui/UserCoupon.js"));

const Users = lazy(() => import("../views/ui/Users"));
const AddUsers = lazy(() => import("../views/ui/AddUser"));

const Orders = lazy(() => import("../views/ui/Orders"));
const ViewOrders = lazy(() => import("../views/ui/ViewOrders"));

const AllOrders = lazy(() => import("../views/ui/AllOrders"));
const OrdersView = lazy(() => import("../views/ui/OrderView"));

const Purchase = lazy(() => import("../views/ui/Purchase.js"));
const AddPurchase = lazy(() => import("../views/ui/AddPurchase.js"));

const Sauces = lazy(() => import("../views/ui/Sauces.js"));
const AddSauces = lazy(() => import("../views/ui/AddSauces.js"));

const Toppings = lazy(() => import("../views/ui/Toppings.js"));
const AddToppings = lazy(() => import("../views/ui/AddToppings.js"));

const Cheese = lazy(() => import("../views/ui/Cheese.js"));
const AddCheese = lazy(() => import("../views/ui/AddCheese.js"));

const DrinksBrand = lazy(() => import("../views/ui/DrinksBrand.js"));
const AddDrinksBrand = lazy(() => import("../views/ui/AddDrinksBrand.js"));

const SoftDrinks = lazy(() => import("../views/ui/SoftDrinks.js"));
const AddSoftDrinks = lazy(() => import("../views/ui/AddSoftDrinks.js"));

const FountainDrinks = lazy(() => import("../views/ui/FountainDrinks.js"));
const AddFountainDrinks = lazy(() => import("../views/ui/AddFountainDrinks.js"));

const Fries = lazy(() => import("../views/ui/Fries.js"));
const AddFries = lazy(() => import("../views/ui/AddFries.js"));

const FriesSize = lazy(() => import("../views/ui/FriesSize.js"));
const AddFriesSize = lazy(() => import("../views/ui/AddFriesSize.js"));

const FriesSizePrice = lazy(() => import("../views/ui/FriesSizePrice.js"));
const AddFriesSizePrice = lazy(() => import("../views/ui/AddFriesSizePrice.js"));

const Sides = lazy(() => import("../views/ui/Sides.js"));
const AddSides = lazy(() => import("../views/ui/AddSides.js"));

const SidesRule = lazy(() => import("../views/ui/SidesRule.js"));
const AddSidesRule = lazy(() => import("../views/ui/AddSidesRule.js"));

const TodayOrderView = lazy(() => import("../views/ui/TodayOrderView.js"));


const CookingSauces = lazy(() => import("../views/ui/CookingSauces.js"));
const AddCookingSauces = lazy(() => import("../views/ui/AddCookingSauces.js"));

const SaucesRule = lazy(() => import("../views/ui/SaucesRule.js"));
const AddSaucesRule = lazy(() => import("../views/ui/AddSaucesRule.js"));

const SaleReport = lazy(() => import("../views/ui/SaleReport.js"));
const PurchaseReport = lazy(() => import("../views/ui/PurchaseReport.js"));
const StockReport = lazy(() => import("../views/ui/StockReport.js"));

const IngredientPurchaseReport = lazy(() => import("../views/ui/IngredientPurchaseReport.js"));
const IngredientStockReport = lazy(() => import("../views/ui/IngredientStockReport.js"));

const PartnersRequest = lazy(() => import("../views/ui/PartnersRequest"));
const MemberPricing = lazy(() => import("../views/ui/MemberPricing"));
const AddMemberPricing = lazy(() => import("../views/ui/AddMemberPricing"));


const SubProduct = lazy(() => import("../views/ui/SubProduct.js"));
const AddSubProduct = lazy(() => import("../views/ui/AddSubProduct.js"));

const Pos = lazy(() => import("../views/ui/Pos.js"));



const Ingredients = lazy(() => import("../views/ui/Ingredients.js"));
const AddIngredients = lazy(() => import("../views/ui/AddIngredients.js"));

const Tax = lazy(() => import("../views/ui/Tax.js"));
const AddTax = lazy(() => import("../views/ui/AddTax.js"));


const IngredientPurchase = lazy(() => import("../views/ui/IngredientPurchase.js"));
const AddIngredientPurchase = lazy(() => import("../views/ui/AddIngredientPurchase.js"));

const Recipe = lazy(() => import("../views/ui/Recipe.js"));
const AddRecipe = lazy(() => import("../views/ui/AddRecipe.js"));

// ******** KITCHEN DASHBOARD STARTS ********
const PendingOrders = lazy(() => import("../views/ui/PendingOrders"));
const CompletedOrders = lazy(() => import("../views/ui/CompletedOrders"));

// ******** KITCHEN DASHBOARD ENDS ********

// ******** RIDRES DASHBOARD STARTS ********
const RcurrentOrders = lazy(() => import("../views/ui/Rider-current-orders"));
const DeliveredOrders = lazy(() => import("../views/ui/DeliveredOrders"));

const Riders = lazy(() => import("../views/ui/Riders"));
// ******** RIDRES DASHBOARD ENDS ********

// ******** GAS ADMIN STARTS ********
const LocationInvoices = lazy(() => import("../views/ui/LocationInvoices"));

const Location = lazy(() => import("../views/ui/Location"));
const AddLocation = lazy(() => import("../views/ui/AddLocations"));

const GasPrices = lazy(() => import("../views/ui/GasPrices"));
const AddGasPrices = lazy(() => import("../views/ui/AddGasPrices"));

const ManageUsers = lazy(() => import("../views/ui/ManageUsers"));
const AddManageUsers = lazy(() => import("../views/ui/AddManageUsers"));

const GasVariations = lazy(() => import("../views/ui/GasVariations"));
const AddVariations = lazy(() => import("../views/ui/AddGasVariation"));

const GasInvoices = lazy(() => import("../views/ui/GasInvoices"));
const AddGasInvoices = lazy(() => import("../views/ui/AddGasInvoices"));

const PartnerView = lazy(() => import("../views/ui/ViewPartnerDetails"));
const Dealers = lazy(() => import("../views/ui/DealersActive"));

const GasPaymentBills = lazy(() => import("../views/ui/GasPaymentBills.js"));

const SeoTags = lazy(() => import("../views/ui/SeoTags.js"));
const AddSeoTags = lazy(() => import("../views/ui/AddSeoTags.js"));
// ******** GAS ADMIN ENDS ********


/*****Routes******/

export const ThemeRoutes = [
  {
    path: "/",

    element: <FullLayout />,
    
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", exact: true, element: <Starter /> },

      { path: "/product", exact: true, element: <Product /> },
      { path: "/product/detail/:id", exact: true, element: <ProductDetail /> },
      { path: "/product/add-product", exact: true, element: <AddProduct /> },
      { path: "/product/edit/:id", exact: true, element: <AddProduct /> },
      
      { path: "/sub-product", exact: true, element: <SubProduct /> },
      // { path: "/sub-product/detail/:id", exact: true, element: <ProductDetail /> },
      { path: "/sub-product/add-sub-product", exact: true, element: <AddSubProduct /> },
      { path: "/sub-product/edit/:id", exact: true, element: <AddSubProduct /> },

      { path: "/category", exact: true, element: <Category /> },
      { path: "/category/add-category", exact: true, element: <AddCategory /> },
      { path: "/category/edit/:id", exact: true, element: <AddCategory /> },

      // { path: "/subcategory/add-subcategory", exact: true, element: <AddSubCategory /> },
      // { path: "/subcategory/edit/:id", exact: true, element: <AddSubCategory /> },
      // { path: "/subcategory", exact: true, element: <SubCategory /> },

      { path: "/addons", exact: true, element: <Addons /> },
      { path: "/addons/add-addons", exact: true, element: <AddAddons /> },
      { path: "/addons/edit/:id", exact: true, element: <AddAddons /> },

      { path: "/sauces", exact: true, element: <Sauces /> },
      { path: "/sauces/add-sauces", exact: true, element: <AddSauces /> },
      { path: "/sauces/edit/:id", exact: true, element: <AddSauces /> },

      { path: "/toppings", exact: true, element: <Toppings /> },
      { path: "/toppings/add-toppings", exact: true, element: <AddToppings /> },
      { path: "/toppings/edit/:id", exact: true, element: <AddToppings /> },

      { path: "/cheese", exact: true, element: <Cheese /> },
      { path: "/cheese/add-cheese", exact: true, element: <AddCheese /> },
      { path: "/cheese/edit/:id", exact: true, element: <AddCheese /> },

      { path: "/friesSize", exact: true, element: <FriesSize /> },
      { path: "/friesSize/add-friesSize", exact: true, element: <AddFriesSize /> },
      { path: "/friesSize/edit/:id", exact: true, element: <AddFriesSize /> },

      { path: "/friesSizePrice", exact: true, element: <FriesSizePrice /> },
      { path: "/friesSizePrice/add-friesSizePrice", exact: true, element: <AddFriesSizePrice /> },
      { path: "/friesSizePrice/edit/:id", exact: true, element: <AddFriesSizePrice /> },

      { path: "/fries", exact: true, element: <Fries /> },
      { path: "/fries/add-fries", exact: true, element: <AddFries /> },
      { path: "/fries/edit/:id", exact: true, element: <AddFries /> },

      { path: "/sides", exact: true, element: <Sides /> },
      { path: "/sides/add-sides", exact: true, element: <AddSides /> },
      { path: "/sides/edit/:id", exact: true, element: <AddSides /> },


      { path: "/sidesRule", exact: true, element: <SidesRule /> },
      { path: "/sidesRule/add-sidesRule", exact: true, element: <AddSidesRule /> },
      { path: "/sidesRule/edit/:id", exact: true, element: <AddSidesRule /> },

      { path: "/drinksbrand", exact: true, element: <DrinksBrand /> },
      { path: "/drinksbrand/add-drinksbrand", exact: true, element: <AddDrinksBrand /> },
      { path: "/drinksbrand/edit/:id", exact: true, element: <AddDrinksBrand /> },

      { path: "/softdrinks", exact: true, element: <SoftDrinks /> },
      { path: "/softdrinks/add-softdrinks", exact: true, element: <AddSoftDrinks /> },
      { path: "/softdrinks/edit/:id", exact: true, element: <AddSoftDrinks /> },

      { path: "/fountaindrinks", exact: true, element: <FountainDrinks /> },
      { path: "/fountaindrinks/add-fountaindrinks", exact: true, element: <AddFountainDrinks /> },
      { path: "/fountaindrinks/edit/:id", exact: true, element: <AddFountainDrinks /> },
      
      { path: "/cookingsauces", exact: true, element: <CookingSauces /> },
      { path: "/cookingsauces/add-cookingsauces", exact: true, element: <AddCookingSauces /> },
      { path: "/cookingsauces/edit/:id", exact: true, element: <AddCookingSauces /> },

      { path: "/today-order-view", exact: true, element: <TodayOrderView /> },
      

      { path: "/saucesRule", exact: true, element: <SaucesRule /> },
      { path: "/saucesRule/add-saucesRule", exact: true, element: <AddSaucesRule /> },
      { path: "/saucesRule/edit/:id", exact: true, element: <AddSaucesRule /> },
      
      { path: "/main-category", exact: true, element: <MainCategory /> },
      { path: "/main-category/add", exact: true, element: <AddMainCategory /> },
      { path: "/main-category/edit/:id", exact: true, element: <AddMainCategory /> },
    
      { path: "/banner", exact: true, element: <Banner /> },
      { path: "/banner/add", exact: true, element: <AddBanner /> },
      { path: "/banner/edit/:id", exact: true, element: <AddBanner /> },

      { path: "/display-menu", exact: true, element: <DisplayMenu /> },
      { path: "/display-menu/add", exact: true, element: <AddDisplayMenu /> },
      { path: "/display-menu/edit/:id", exact: true, element: <AddDisplayMenu /> },

      { path: "/coupon", exact: true, element: <Coupon /> },
      { path: "/user-coupon", exact: true, element: <UserCoupon /> },
      { path: "/coupon/add-coupon", exact: true, element: <AddCoupon /> },
      { path: "/coupon/edit/:id", exact: true, element: <AddCoupon /> },

      { path: "/users", exact: true, element: <Users /> },
      { path: "/users/add", exact: true, element: <AddUsers /> },

      { path: "/pos", exact: true, element: <Pos /> },


      { path: "/orders", exact: true, element: <Orders /> },
      { path: "/orders/view-orders/:id", exact: true, element: <ViewOrders /> },

      { path: "/all-orders", exact: true, element: <AllOrders /> },
      { path: "/all-orders/order-view/:id", exact: true, element: <OrdersView /> },

      { path: "/purchase", exact: true, element: <Purchase /> },
      { path: "/purchase/add", exact: true, element: <AddPurchase /> },
      { path: "/purchase/edit/:id", exact: true, element: <AddPurchase /> },


      { path: "/ingredients", exact: true, element: <Ingredients /> },
      { path: "/ingredients/add", exact: true, element: <AddIngredients /> },
      { path: "/ingredients/edit/:id", exact: true, element: <AddIngredients /> },
     
      { path: "/ingredients-purchase", exact: true, element: <IngredientPurchase /> },
      { path: "/ingredients-purchase/add", exact: true, element: <AddIngredientPurchase /> },
      { path: "/ingredients-purchase/edit/:id", exact: true, element: <AddIngredientPurchase /> },
     
      { path: "/tax", exact: true, element: <Tax /> },
      { path: "/tax/add", exact: true, element: <AddTax /> },
      { path: "/tax/edit/:id", exact: true, element: <AddTax /> },
  
      { path: "/recipe", exact: true, element: <Recipe /> },
      { path: "/recipe/add", exact: true, element: <AddRecipe /> },
      { path: "/recipe/edit/:id", exact: true, element: <AddRecipe /> },
      
      { path: "/sale-report", exact: true, element: <SaleReport /> },
      { path: "/purchase-report", exact: true, element: <PurchaseReport /> },
      { path: "/stock-report", exact: true, element: <StockReport /> },

      { path: "/ingredient-purchase-report", exact: true, element: <IngredientPurchaseReport /> },
      { path: "/ingredient-stock-report", exact: true, element: <IngredientStockReport /> },


      { path: "/pending-orders", exact: true, element: <PendingOrders /> },
      { path: "/completed-orders", exact: true, element: <CompletedOrders /> },

      { path: "/dealers-request", exact: true, element: <PartnersRequest /> },


      { path: "/member-pricing", exact: true, element: <MemberPricing /> },
      { path: "/member-pricing/add", exact: true, element: <AddMemberPricing /> },
      { path: "/member-pricing/eidt/:id", exact: true, element: <AddMemberPricing /> },


      { path: "/current-orders", exact: true, element: <RcurrentOrders /> },
      { path: "/delivered-orders", exact: true, element: <DeliveredOrders /> },
      // { path: "/member-pricing/add", exact: true, element: <AddMemberPricing /> },
      { path: "/riders", exact: true, element: <Riders /> },

// ******** GAS ADMIN STARTS ********

      { path: "/LocationInvoices/:id", exact: true, element: <LocationInvoices /> },

      { path: "/locations", exact: true, element: <Location /> },
      { path: "/add-locations", exact: true, element: <AddLocation /> },
      { path: "/edit-locations/:id", exact: true, element: <AddLocation /> },

      { path: "/gas-prices", exact: true, element: <GasPrices /> },
      { path: "/gas-prices/add", exact: true, element: <AddGasPrices /> },
      { path: "/gas-prices/edit/:id", exact: true, element: <AddGasPrices /> },

      { path: "/manage-users", exact: true, element: <ManageUsers /> },
      { path: "/add-users", exact: true, element: <AddManageUsers /> },

      { path: "/variations", exact: true, element: <GasVariations /> },
      { path: "/variations/add", exact: true, element: <AddVariations /> },
      { path: "/variations/edit/:id", exact: true, element: <AddVariations /> },

      { path: "/accounts", exact: true, element: <GasInvoices /> },
      { path: "/accounts/add", exact: true, element: <AddGasInvoices /> },

      { path: "/dealers/view-detail", exact: true, element: <PartnerView /> },
      { path: "/dealers", exact: true, element: <Dealers /> },

      { path: "/gas-bills", exact: true, element: <GasPaymentBills /> },
      
      { path: "/seo-meta-tags", exact: true, element: <SeoTags /> },
      { path: "/seo-meta-tags/add", exact: true, element: <AddSeoTags /> },
      { path: "/seo-meta-tags/edit/:id", exact: true, element: <AddSeoTags /> },
// ******** GAS ADMIN ENDS ********

    ],
  },
];



// export default ThemeRoute

export const Beforeroutes = [
  {
    path: "/",
    // element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/login" /> },
      { path: "/login", exact: true, element: <Login /> },
    ],
  },
];